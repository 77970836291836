import $ from 'jquery';
import prestashop from 'prestashop';

function setUpCheckout() {
  if ($('.js-cancel-address').length !== 0) {
    $('.checkout-step:not(.js-current-step) .step-title').addClass('not-allowed');
  }

  $('.js-terms a').on('click', function(event) {
    event.preventDefault();
    var url = $(event.target).attr('href');
    if (url) {
      // TODO: Handle request if no pretty URL
      url += `?content_only=1`;
      $.get(url, function(content) {
        $('.js-modal-content').html($(content).find('.page-cms').contents());
        $('#modal').modal('show');
      }).fail(function(resp) {
        prestashop.emit('handleError', {eventType: 'clickTerms', resp: resp});
      });
    }
  });

  $('.js-gift-checkbox').on('click', function() {
    $('#gift').collapse('toggle');
  });
}

$(document).ready(function() {
  var messages = [],
      delivery_message = '';

  if ($('body#checkout').length === 1) {
    setUpCheckout();
  }

  prestashop.on('updatedDeliveryForm', function(params) {
    if (typeof params.deliveryOption === 'undefined' || 0 === params.deliveryOption.length) {
      return;
    }
    // Hide all carrier extra content ...
    $('.carrier-extra-content').hide();
    // and show the one related to the selected carrier
    params.deliveryOption.find('.carrier-extra-content').slideDown();
  });


    $(document).on('click', '.totcheckout_valid', function(e) {
          e.preventDefault();
          var url = $(this).attr('href')+'?';
          var delivery_message =  $('#delivery_message').val();

          if  (delivery_message.length > 0) {
            url +=  'delivery_message=' + delivery_message;
          }


        $('[data-product-message-text]').each(function() {
                console.log(this);
                console.log($(this).val());
                var id_product = $(this).data('id-product');
                var id_product_attr = $(this).data('id-product-attribute');
                var key = "product_message_"+id_product+"_"+id_product_attr;
                if  ($(this).val().length > 0) {
                    url += '&'+key +'=' + $(this).val();
                }
            });

          document.location.href = url;
        }
    );

  $(document).on('click', '[data-product-message]', function(e) {
    e.preventDefault();
    var id_product = $(this).data('id-product');
    var id_product_attr = $(this).data('id-product-attribute');
    $("#product_message_"+id_product+"_"+id_product_attr).toggle();
  });

  prestashop.on('updateCart', function() {
    $('[data-product-message-text]').each(function(i, item) {
      messages[i] = [];
      messages[i]['value'] = $(item).val();  
      if ($(item).parent('div').is(":visible") === true && $(item).val() !== '') {
        messages[i]['shown'] = true; 
      } else {
        messages[i]['shown'] = false; 
      }
    });
    delivery_message = $('#delivery_message').val();
  });

  prestashop.on('updatedCart', function() {
    $('[data-product-message-text]').each(function(i, item) {
      $(this).val(messages[i]['value']);
      if (messages[i]['shown'] === true) {
        let id_product = $(this).data('id-product');
        let id_product_attr = $(this).data('id-product-attribute');
        $(`#product_message_${id_product}_${id_product_attr}`).show();
      }
    });
    $('#delivery_message').val(delivery_message);
  });
});
