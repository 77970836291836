import $ from 'jquery';

function initRmaItemSelector() {
  $('#order-return-form table thead input[type=checkbox]').on('click', function() {
    var checked = $(this).prop('checked');
    $('#order-return-form table tbody input[type=checkbox]').each(function(_, checkbox) {
      $(checkbox).prop('checked', checked);
    });
  });
}

function setupCustomerScripts() {
  if ($('body#order-detail')) {
    initRmaItemSelector();
  }
}

$(document).ready(setupCustomerScripts);

$(document).ready(function() {
  $(document).on('change', 'input[name="company"]', function (e) {
        var company = $(this).val();
        siretToggle(company)
      }
  );

});

siretToggle($('input[name="company"]').val());

function siretToggle(company) {
    if (company) {
        $('input[name="siret"]').prop('required', true);
        $('input[name="siret"]').closest('div.siret').find('.form-control-comment').hide();
        $('#info_siren').show();
    } else {
        $('input[name="siret"]').prop('required', false);
        $('input[name="siret"]').closest('div.siret').find('.form-control-comment').show();
        $('#info_siren').hide();
    }
}